@import "tailwindcss/base";

@import "tailwindcss/components";
@import "./components/Fonts.css";
@import "./components/Html.css";
@import "./components/Body.css";
@import "./components/Menu.css";
@import "./components/Footer.css";
@import "./components/BannerHome.css";
@import "./components/BannerSobre.css";
@import "./components/CardBlur.css";
@import "./components/CardIntroducao.css";
@import "./components/CardServicos.css";
@import "./components/CardPlanos.css";
@import "./components/Contato.css";

@import "tailwindcss/utilities";

@layer components {
  .ornamento-verde {
    @apply w-32 h-[.1875rem] bg-primary-green-pure block rounded-lg;
  }
}

@layer components {
  .botao-primario {
    @apply inline-flex justify-center items-center h-48 px-[12px] py-[10.5px] bg-primary-green-pure  rounded-lg text-base leading-120 font-semibold text-neutral-black-8-7  transition-all duration-500;
  }
  .botao-primario:hover{
    box-shadow: 0 0.5em 0.5em -0.4em #20ff6a;
    transform: translateY(-0.25em)!important;
    background-color: #20ff6a!important;
  }
}

@layer components {
  .titulo-secao {
    @apply flex flex-col;

    h2 {
      @apply font-sf-pro text-neutral-80 text-5xl mdt:text-[2rem] leading-120;
      br {
        @apply mdt:hidden;
      }
    }
    p {
      @apply font-normal text-lg leading-120 text-neutral-40 mt-16;
      strong {
        @apply font-normal text-primary-green-dark;
      }
    }
  }
}

@layer components {
  .ornamento-roda {
    @apply bg-primary-green-pure w-32 h-32 rounded-full flex justify-center items-center relative;
    &::before {
      content: "";
      @apply absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 w-64 h-64 bg-primary-green-pure-10-op rounded-full;
    }
    .quadrado {
      @apply w-4 h-4 bg-white;
    }
  }
}

@layer components {
  .tab-content {
    @apply hidden;
    &.active {
      @apply block;
      animation: tabAnimation 0.3s ease-in-out forwards;
    }
  }
}

@keyframes tabAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
